<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <form class="form">
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Contacts") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Update the physical person's contacts") }}
            </span>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click.prevent="handleSubmit(save)">
              {{ $t("Save Changes") }}
            </button>
          </div>
        </div>
        <div class="card-body detail">
          <h6 class="mt-3">{{ $t("Phone contacts") }}</h6>
          <multi-telephones-input :key="multiTelephonesInputKey" v-model="telContactsCpy" :options="TELEPHONE_KINDS"
            @updated="handleSubmit(save)"></multi-telephones-input>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="mt-3">{{ $t("Email contacts") }}</h6>
          <multi-emails-input :key="multiEmailsInputKey" v-model="emailsContactsCpy" :options="EMAIL_KINDS"
            @updated="handleSubmit(save)"></multi-emails-input>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="mt-3">{{ $t("Social contacts") }}</h6>
          <validation-provider v-slot="{ errors }" vid="social_contacts">
            <multi-social-input :key="multiSocialInputKey" v-model="socialContactsCpy" :options="SOCIAL_KINDS"
              :error-messages="errors" @updated="handleSubmit(save)"></multi-social-input>
          </validation-provider>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import icons from "@/core/config/icons.js";
import PhysicalPersonEmailService from "@/core/services/physical-person/physical-person-email.service";
import PhysicalPersonSocialService from "@/core/services/physical-person/physical-person-social.service";
import { backendErrorSwal, validationErrorSwal, unsavedChangesSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';

export default {
  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons,
      originalData: {
        tel: null,
        email: null,
        social: null
      },
      apiErrors: null,
      isSaving: false,
      // Tel
      telContactsCpy: [],
      multiTelephonesInputKey: 0,
      TELEPHONE_KINDS: [],
      // Email
      emailsContactsCpy: [],
      multiEmailsInputKey: 1,
      EMAIL_KINDS: [],
      //Social
      socialContactsCpy: [],
      multiSocialInputKey: 2,
      SOCIAL_KINDS: []
    };
  },

  computed: {
    // The API needs a persona ID with every social contact.
    socialContactsWithPersona() {
      return this.socialContactsCpy.map((el) => ({ ...el, persona: this.physicalPerson.id }));
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        let newErrors = {};

        if (val?.telcontact_set) {
          val.telcontact_set.forEach((el, idx) => {
            el.number ? (newErrors[`phone[${idx}]`] = el.number[0]) : {};
          });
        }

        if (val?.extraemail_set) {
          val.extraemail_set.forEach((el, idx) => {
            el.email ? (newErrors[`email[${idx}]`] = el.email[0]) : {};
          });
        }

        // To check
        if (val?.socialcontact_set) {
          val.socialcontact_set.forEach((el, idx) => {
            el.social ? (newErrors[`social[${idx}]`] = el.social[0]) : {};
          });
        }

        formObs.setErrors({ ...newErrors });
      },
    },
    physicalPerson: {
      deep: true,
      immediate: true,
      handler() {
        this.resetContacts()
      }
    }
  },
  created() {
    // Tel
    PhysicalPersonTelephoneService.getKinds()
      .then((res) => res.map((el) => ({ value: el.value, text: el.display_name })))
      .then((telKinds) => (this.TELEPHONE_KINDS = telKinds))
      .catch((err) => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    this.resetTelContacts();
    // Email
    PhysicalPersonEmailService.getKinds()
      .then(res => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(emailKinds => (this.EMAIL_KINDS = emailKinds))
      .catch(err => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    this.resetEmailContacts();
    // Soc
    PhysicalPersonSocialService.getKinds()
      .then((r) => r.map((el) => ({ value: el.value, text: el.display_name })))
      .then((socialKinds) => (this.SOCIAL_KINDS = socialKinds))
      .catch((err) => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    this.resetSocialContacts();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Contacts") },
    ]);
    this.resetContacts();
  },
  methods: {
    async updateMain(tel) {
      const exMain = await this.telContactsCpy.find((el) => el.main && el.number !== tel.number);
      if (exMain) exMain.main = false;
      const phone = await this.telContactsCpy.find((el) => el.id === tel.id);
      phone.main = true;
      await this.save();
      this.resetTelContacts();
    },

    async save() {
      this.isSaving = true;
      this.$refs.validationObserver.validate().then(async (isValid) => {
        if (!isValid) {
          validationErrorSwal();
          return;
        } else {
          await PhysicalPersonService.update({
            // id, name, surname, place_birth are required for the API to work.
            id: this.physicalPerson.id,
            name: this.physicalPerson.name,
            surname: this.physicalPerson.surname,
            telcontact_set: this.telContactsCpy.filter(phone => phone.number != ""),
            extraemail_set: this.emailsContactsCpy.filter(email => email.email != ""),
            socialcontact_set: this.socialContactsWithPersona,
          }, this.physicalPerson.id).then(() => {
            this.$emit("updated");
            successToast();
          }).catch((err) => {
            if (err?.response?.status === 400) {
              this.apiErrors = err.response.data;
            } else {
              backendErrorSwal(err?.response?.data?.detail, err);
            }
          }).finally(() => {
            this.isSaving = false;
          });
        }
      })
    },
    resetTelContacts() {
      this.telContactsCpy = _.cloneDeep(this.physicalPerson.telcontact_set);
      this.originalData.tel = _.cloneDeep(this.telContactsCpy);
    },
    resetEmailContacts() {
      this.emailsContactsCpy = _.cloneDeep(this.physicalPerson.extraemail_set);
      this.originalData.email = _.cloneDeep(this.emailsContactsCpy);
    },
    resetSocialContacts() {
      this.socialContactsCpy = _.cloneDeep(this.physicalPerson.socialcontact_set);
      this.originalData.social = _.cloneDeep(this.socialContactsCpy);
    },
    resetContacts() {
      this.resetTelContacts();
      this.resetEmailContacts();
      this.resetSocialContacts();
    }
  },

  async beforeRouteLeave(__, ___, next) {
    // Tel
    const arrToCompare = this.telContactsCpy.filter(num => num.number != "").map((num) => ({ ...num, number: num.number.replaceAll(" ", "") }));
    // Email
    const ogNoUpdatedAt = this.physicalPerson.extraemail_set.filter(email => email.email != "").map(mail => {
      const cpy = { ...mail };
      delete cpy.updated;
      return cpy;
    });
    const formNoUpdatedAt = this.emailsContactsCpy.filter(email => email.email != "").map(mail => {
      const cpy = { ...mail };
      delete cpy.updated;
      return cpy;
    });
    if (
      !_.isEqual(arrToCompare, this.originalData.tel) ||
      !_.isEqual(formNoUpdatedAt, ogNoUpdatedAt) ||
      !_.isEqual(this.socialContactsCpy, this.originalData.social)) {
      const res = await unsavedChangesSwal();
      if (res.isConfirmed) await this.save();
      if (res.isDismissed) return;
    }
    next();
  }
}
</script>
