var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Contacts")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Update the physical person's contacts")) + " ")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body detail"
        }, [_c('h6', {
          staticClass: "mt-3"
        }, [_vm._v(_vm._s(_vm.$t("Phone contacts")))]), _c('multi-telephones-input', {
          key: _vm.multiTelephonesInputKey,
          attrs: {
            "options": _vm.TELEPHONE_KINDS
          },
          on: {
            "updated": function updated($event) {
              return handleSubmit(_vm.save);
            }
          },
          model: {
            value: _vm.telContactsCpy,
            callback: function callback($$v) {
              _vm.telContactsCpy = $$v;
            },
            expression: "telContactsCpy"
          }
        }), _c('div', {
          staticClass: "separator separator-dashed my-5"
        }), _c('h6', {
          staticClass: "mt-3"
        }, [_vm._v(_vm._s(_vm.$t("Email contacts")))]), _c('multi-emails-input', {
          key: _vm.multiEmailsInputKey,
          attrs: {
            "options": _vm.EMAIL_KINDS
          },
          on: {
            "updated": function updated($event) {
              return handleSubmit(_vm.save);
            }
          },
          model: {
            value: _vm.emailsContactsCpy,
            callback: function callback($$v) {
              _vm.emailsContactsCpy = $$v;
            },
            expression: "emailsContactsCpy"
          }
        }), _c('div', {
          staticClass: "separator separator-dashed my-5"
        }), _c('h6', {
          staticClass: "mt-3"
        }, [_vm._v(_vm._s(_vm.$t("Social contacts")))]), _c('validation-provider', {
          attrs: {
            "vid": "social_contacts"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('multi-social-input', {
                key: _vm.multiSocialInputKey,
                attrs: {
                  "options": _vm.SOCIAL_KINDS,
                  "error-messages": errors
                },
                on: {
                  "updated": function updated($event) {
                    return handleSubmit(_vm.save);
                  }
                },
                model: {
                  value: _vm.socialContactsCpy,
                  callback: function callback($$v) {
                    _vm.socialContactsCpy = $$v;
                  },
                  expression: "socialContactsCpy"
                }
              })];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }