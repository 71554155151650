import api from "../api.service";

class PhysicalPersonEmailService {
  async getKinds() {
    const res = await api.options("physicalperson-extraemail/");
    return res.data.actions.POST.kind.choices;
  }

  async getAllByPhysicalPerson(personID) {
    const res = await this.getAllByPhysicalPersonPaginated(personID, 1000, 0);
    return res.results;
  }

  async getAllByPhysicalPersonPaginated(personID, limit, offset) {
    const res = await api.get("physicalperson-extraemail/", {
      params: { persona: personID, limit, offset },
    });
    return res.data;
  }

  async create(email) {
    const res = await api.post("physicalperson-extraemail/", email);
    return res.data;
  }

  async delete(email) {
    await api.delete(`physicalperson-extraemail/${email.id}`);
  }
}

export default new PhysicalPersonEmailService();
