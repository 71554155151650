import api from "../api.service";

class PhysicalPersonTelephoneService {
  async getOne(id) {
    const res = await api.get(`/physicalperson-telcontact/${id}/`);
    return res;
  }
  async getKinds() {
    const res = await api.options("/physicalperson-telcontact/");
    return res.data.actions.POST.kind.choices;
  }

  async getAllByPhysicalPerson(personID) {
    const res = await this.getAllByPhysicalPersonPaginated(personID, 1000, 0);
    return res.results;
  }

  async getAllByPhysicalPersonPaginated(personID, limit, offset) {
    const res = await api.get("/physicalperson-telcontact/", {
      params: {
        persona: personID,
        limit,
        offset
      },
    });
    return res.data;
  }

  async create(telelphone) {
    const res = await api.post("/physicalperson-telcontact/", telelphone);
    return res.data;
  }

  async update(telephone) {
    const res = await api.patch(`/physicalperson-telcontact/${telephone.id}/`, telephone);
    return res.data;
  }

  async delete(telephone) {
    await api.delete(`/physicalperson-telcontact/${telephone.id}/`, telephone);
  }

  async setMain(telephone) {
    const body = {
      kind: telephone.kind,
      main: !telephone.main,
      number: telephone.number,
      persona: telephone.persona
    };
    const res = await api.post(`/physicalperson-telcontact/${telephone.id}/set_main/`, body);
    return res.data;
  }
}

export default new PhysicalPersonTelephoneService();