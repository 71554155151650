import api from "../api.service";

class PhysicalPersonSocialService {
  async getKinds() {
    const res = await api.options("physicalperson-socialcontact/");
    return res.data.actions.POST.kind.choices;
  }

  async getAllByPhysicalPerson(personID) {
    const res = await this.getAllByPhysicalPersonPaginated(personID, 1000, 0);
    return res.results;
  }

  async getAllByPhysicalPersonPaginated(personID, limit, offset) {
    const res = await api.get("physicalperson-socialcontact/", {
      params: { persona: personID, limit, offset },
    });
    return res.data;
  }

  async create(socialContact) {
    const res = await api.post("physicalperson-socialcontact/", socialContact);
    return res.data;
  }

  async update(socialContact) {
    const res = await api.patch(`physicalperson-socialcontact/${socialContact.id}/`, socialContact);
    return res.data;
  }

  async delete(socialContact) {
    await api.delete(`physicalperson-socialcontact/${socialContact.id}/`, socialContact);
  }
}

export default new PhysicalPersonSocialService();
